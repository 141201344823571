'use client';

import * as React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import LayoutForm from '@/components/ui/LayoutForm';
import FormRow from '@/components/ui/FormRow';
import ControlInput from '@/components/ui/ControlInput';
import ControlSelect from '@/components/ui/ControlSelect';
import ControlTextarea from '@/components/ui/ControlTextarea';
import Buttons from '@/components/ui/Buttons';
import ButtonsPrimary from '@/components/ui/Buttons/Primary';
import Button from '@/components/ui/Button';
import Callout from '@/components/ui/Callout';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { ContactFormValuesTypeEnum } from '@/lib/enums/contactFormValuesType.enum';
import TurnstileChallenge from '@/components/turnstile/TurnstileChallenge';
import { PersonFilterOptions } from '@/components/content/MarketingListTool/interfaces/personFilterOptions.interface';
import useGaEvents from '@/lib/hooks/useGaEvents';

export interface IContactFormProps {
  onSubmit?: (values: IFormState) => void;
  onCancel?: () => void;
  selectedForm?: IMarketingListToolFilter;
  isPersonFilter?: boolean;
  formType?: 'MARKETING' | 'BACKGROUND' | 'CONTACT';
  options?: PersonFilterOptions;
  analyticsId?: string;
}

interface IFormState {
  name: string;
  phone: string;
  email: string;
  category: string;
  message: string;
}

interface IErrorState {
  name?: string;
  phone?: string;
  email?: string;
  category?: string;
}

const ContactForm: React.FC<IContactFormProps> = ({ selectedForm, isPersonFilter, formType, options, analyticsId }) => {
  const initialState: IFormState = {
    name: '',
    phone: '',
    email: '',
    category: '',
    message: '',
  };
  const [form, setForm] = React.useState<IFormState>(initialState);
  const [errors, setErrors] = React.useState<IErrorState>({});
  const [formHasError, setFormHasError] = React.useState<boolean>(false);
  const [sent, setSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formFocused, setFormFocused] = React.useState(false);
  const [challengeResponse, setChallengeResponse] = React.useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const { trackGenerateLead } = useGaEvents();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('components.askOffer.required')),
    phone: Yup.string()
      .matches(/^\d+$/, t('components.askOffer.validation.wrongPhoneNumber'))
      .required(t('components.askOffer.required'))
      .min(7, t('components.askOffer.validation.wrongPhone')),
    email: Yup.string().email(t('components.askOffer.validation.wrongEmail')).required(t('components.askOffer.required')),
    category: Yup.string().notOneOf(['0'], t('components.askOffer.required')).required(t('components.askOffer.required')),
    message: Yup.string(),
  });

  const validate = async (formData: IFormState): Promise<IErrorState> => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      return {};
    } catch (validationErrors) {
      const formattedErrors: IErrorState = {};
      if (validationErrors instanceof Yup.ValidationError) {
        validationErrors.inner.forEach((error) => {
          if (error.path) {
            formattedErrors[error.path as keyof IErrorState] = error.message;
          }
        });
      }
      return formattedErrors;
    }
  };

  const setFormInitialState = () => {
    setForm(initialState);
    setErrors({});
    setFormHasError(false);
  };

  const onSuccess = () => {
    trackGenerateLead({
      section: analyticsId || '',
      name: form.category,
    });
    setSent(true);
    setFormInitialState();
  };

  const handleSubmit = async () => {
    setLoading(true);
    const validationErrors = await validate(form);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
    } else {
      setErrors({});
      try {
        const emailBody = {
          ...form,
          isPersonFilter: isPersonFilter,
          formType: formType,
          options: options,
          selectedForm: selectedForm,
          challengeResponse,
        };
        const response = await fetch('/api/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(emailBody),
        });

        const body = (await response.json()) as { error?: Record<string, string> } | null;
        if (body && body.error) {
          setFormHasError(true);
          return;
        }
        onSuccess();
      } catch (error) {
        setFormHasError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const onChange = (value: string | File[] | null, id: keyof IFormState) => {
    if (!formFocused) {
      setFormFocused(true);
    }
    setForm((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  return (
    <React.Fragment>
      {sent && (
        <Callout
          intent="success"
          text={t('components.askOffer.success')}
        />
      )}
      {formHasError && (
        <Callout
          intent="danger"
          text={t('components.askOffer.error')}
        />
      )}
      {formFocused && (
        <TurnstileChallenge
          id="contact-form"
          action="contact-form"
          onToken={setChallengeResponse}
        />
      )}
      <LayoutForm>
        <FormRow
          label={t('components.askOffer.name')}
          required={t('components.askOffer.required')}
          size="narrow"
          hasError={errors.name}
        >
          <ControlInput
            value={form.name}
            disabled={loading}
            onChange={(e) => onChange(e.target.value, 'name')}
          />
        </FormRow>
        <FormRow
          label={t('components.askOffer.phone')}
          required={t('components.askOffer.required')}
          size="narrow"
          hasError={errors.phone}
        >
          <ControlInput
            value={form.phone}
            disabled={loading}
            onChange={(e) => onChange(e.target.value, 'phone')}
          />
        </FormRow>
        <FormRow
          label={t('components.askOffer.email')}
          required={t('components.askOffer.required')}
          size="narrow"
          hasError={errors.email}
        >
          <ControlInput
            value={form.email}
            disabled={loading}
            onChange={(e) => onChange(e.target.value, 'email')}
          />
        </FormRow>
        <FormRow
          label={t('components.askOffer.info')}
          required={t('components.askOffer.required')}
          size="narrow"
          hasError={errors.category}
        >
          <ControlSelect
            value={form.category}
            disabled={loading}
            onChange={(e) => onChange(e.target.value, 'category')}
            options={[
              {
                value: t('components.askOffer.options.select'),
                label: t('components.askOffer.options.select'),
              },
              {
                value: ContactFormValuesTypeEnum.BACKGROUND,
                label: t('components.askOffer.options.credit'),
              },
              {
                value: ContactFormValuesTypeEnum.MARKETING,
                label: t('components.askOffer.options.marketing'),
              },
              {
                value: ContactFormValuesTypeEnum.CONTACT,
                label: t('components.askOffer.options.other'),
              },
            ]}
          />
        </FormRow>
        <FormRow label={t('components.askOffer.message')}>
          <ControlTextarea
            disabled={loading}
            minRows={5}
            maxRows={5}
            value={form.message}
            onChange={(e) => onChange(e.target.value, 'message')}
          />
        </FormRow>
      </LayoutForm>
      <Buttons>
        <ButtonsPrimary>
          <Button
            title={t('components.askOffer.button')}
            theme="primary"
            size="large"
            disabled={loading}
            onClick={() => {
              void handleSubmit();
            }}
          />
        </ButtonsPrimary>
      </Buttons>
    </React.Fragment>
  );
};

ContactForm.displayName = 'ContactForm';

export default ContactForm;

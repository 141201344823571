'use client';

import * as React from 'react';
import { PropsWithChildren, useContext, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { AuthContext } from '@/components/auth/AuthProvider';
import useGaEvents from '@/lib/hooks/useGaEvents';

const GlobalPageView: React.FC<PropsWithChildren> = (props) => {
  const { initialized: authInitialized } = useContext(AuthContext);
  const { trackPageView: gaPageView } = useGaEvents();
  const pathname = usePathname();

  useEffect(() => {
    if (authInitialized) {
      gaPageView();
    }
  }, [authInitialized, pathname]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default GlobalPageView;

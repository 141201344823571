import * as React from 'react';
import Script from 'next/script';
import { useEffect, useMemo } from 'react';
import { Turnstile } from '@/lib/types';

interface IProps {
  id: string;
  action: string;
  onToken: (token: string) => void;
}

const TurnstileChallenge: React.FC<IProps> = (props) => {
  const siteKey = process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY!;
  const id = useMemo(() => `#${props.id}`, []);

  const register = () => {
    const args: Parameters<Turnstile['render']> = [
      id,
      {
        'sitekey': siteKey,
        'action': props.action,
        'callback': (token) => props.onToken(token),
        'error-callback': (error) => {
          // Avoid uncaught exceptions
          console.warn('Turnstile error: ', error);
        },
      },
    ];
    if (window.turnstile) {
      window.turnstile.render(...args);
    } else {
      window.onTurnstileLoad =
        window.onTurnstileLoad ||
        function () {
          if (window.turnstile_q?.length) {
            window.turnstile_q.forEach((params) => {
              window.turnstile?.render(...params);
            });
          }
        };
      window.turnstile_q = window.turnstile_q || [];
      window.turnstile_q.push(args);
    }
  };

  useEffect(() => {
    if (!siteKey) {
      return;
    }
    register();
    return () => {
      window.turnstile?.remove(props.id);
    };
  }, [props.id]);

  if (!siteKey) {
    return null;
  }

  return (
    <React.Fragment>
      <Script
        src="https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onTurnstileLoad"
        async={true}
        defer={true}
      />
      <div id={props.id} />
    </React.Fragment>
  );
};

export default TurnstileChallenge;

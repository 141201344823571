'use client';

import * as React from 'react';
import { useContext, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { SiteContext } from '@/components/helpers/siteProvider';

const Hotjar: React.FC = () => {
  const { hotjarId } = useContext(SiteContext);
  useEffect(() => {
    if (hotjarId) {
      hotjar.initialize({
        id: hotjarId,
        sv: 6,
      });
    }
  }, [hotjarId]);
  return null;
};

export default Hotjar;

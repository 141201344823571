'use client';

import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { SiteContext } from '@/components/helpers/siteProvider';
import SearchField from '@/components/ui/SearchField';
import Menu from '@/components/ui/Header/Menu';
import Button from '@/components/ui/Button';
import ButtonIcon from '@/components/ui/ButtonIcon';
import { initLogin } from '@/lib/sso';
import { AuthContext } from '@/components/auth/AuthProvider';
import { useOverlay } from '@/components/hooks/useOverlay';
import MenuMobile from '@/components/ui/Header/MenuMobile';
import SearchMobile from '@/components/ui/Header/SearchMobile';
import LanguageButton from '@/components/ui/Header/LanguageButton';
import { IMenu } from '@/lib/types';
import { Locale } from '@/lib/enums/locale.enum';
import NotificationsButton from '@/components/ui/Header/NotificationsButton';
import { getLocalizedPath } from '@/components/helpers/localize';
import styles from './styles.module.scss';

export interface IHeaderProps {
  menu: IMenu;
  locale: Locale;
}

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const { t } = useTranslation();
  const { menu, locale } = props;
  const { user, logout } = React.useContext(AuthContext);
  const { theme, name } = React.useContext(SiteContext);
  const { isOverlayOpen, openOverlay: openMobileMenu } = useOverlay('mobilemenu');
  const { openOverlay: openMobileSearch } = useOverlay('mobilesearch');
  const router = useRouter();

  return (
    <React.Fragment>
      <div className={styles['header']}>
        <div className={styles['header__main']}>
          <div className={styles['header__main__limiter']}>
            <ButtonIcon
              theme="white-transparent"
              className={styles['header__buttons__burger']}
              onClick={(e) =>
                openMobileMenu(e, {
                  asNew: false,
                  closeRestOverlays: true,
                })
              }
              title={t('generic.actions.openMenu')}
              icon={isOverlayOpen ? 'close' : 'menu'}
            />

            <Link
              href={getLocalizedPath('frontpage', 'frontpage', locale)}
              className={styles['header__logo']}
            >
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={`/logos/${theme}.svg`}
                alt={name}
              />
            </Link>

            <Menu menu={menu} />

            <SearchField />

            <div className={styles['header__buttons']}>
              <LanguageButton locale={locale} />
              {user && <NotificationsButton />}
              <ButtonIcon
                title={t('components.search.placeholder')}
                icon="search"
                theme="white-transparent"
                onClick={(e) =>
                  openMobileSearch(e, {
                    asNew: false,
                    closeRestOverlays: true,
                  })
                }
                className={styles['header__buttons__search']}
              />
              {!user ? (
                <React.Fragment>
                  <Button
                    title={t('components.header.login')}
                    iconLeft="login"
                    theme="white-transparent"
                    onClick={initLogin}
                    className="mobile-hide"
                  />
                  <ButtonIcon
                    title={t('components.header.login')}
                    icon="login"
                    theme="white-transparent"
                    onClick={initLogin}
                    className="not-mobile-hide"
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    title={user.firstName}
                    theme="white-transparent"
                    iconLeft="arrow01-down"
                    menu={[
                      {
                        label: t('account.myAccount'),
                        onClick: () => router.push(`${getLocalizedPath('my-account', 'my-account', locale)}`),
                      },
                      {
                        label: t('components.header.logout'),
                        onClick: logout,
                        icon: 'chevron-left',
                      },
                    ]}
                    className="mobile-hide"
                  />
                  <ButtonIcon
                    title={user.firstName}
                    theme="white-transparent"
                    icon="profile-circle"
                    className="not-mobile-hide"
                    menu={[
                      {
                        label: t('account.myAccount'),
                        onClick: () => router.push(`${getLocalizedPath('my-account', 'my-account', locale)}`),
                      },
                      {
                        label: t('components.header.logout'),
                        onClick: logout,
                      },
                    ]}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <MenuMobile
        menu={menu}
        locale={locale}
      />
      <SearchMobile />
    </React.Fragment>
  );
};

Header.displayName = 'Header';

export default Header;

'use client';

import * as React from 'react';
import { useTranslation } from 'react-i18next';

const ConsentPreferencesButton: React.FC = () => {
  const { t } = useTranslation();

  const openPreferences = () => {
    window.Didomi?.preferences.show?.();
  };

  return (
    <a
      style={{ cursor: 'pointer' }}
      onClick={openPreferences}
    >
      {t('cookie.preferences')}
    </a>
  );
};

export default ConsentPreferencesButton;

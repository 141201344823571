'use client';

import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from '@/components/ui/Overlay';
import LayoutOverlay from '@/components/ui/LayoutOverlay';
import Block from '@/components/ui/Block';
import OverlayTitle from '@/components/ui/LayoutOverlay/OverlayTitle';
import ContactForm from '@/components/content/ContactForm';
import NonIdealState from '@/components/ui/NonIdealState';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import useGaEvents from '@/lib/hooks/useGaEvents';
import { PersonFilterOptions } from '@/components/content/MarketingListTool/interfaces/personFilterOptions.interface';
import useOfferOverlay from '@/lib/hooks/useOfferOverlay';

export type OfferFormType = 'MARKETING' | 'BACKGROUND' | 'CONTACT';

interface IProps {
  form?: IMarketingListToolFilter;
  isPersonFilter?: boolean;
  formType?: OfferFormType;
  options?: PersonFilterOptions;
}

const OverlayAskOffer: React.FC<IProps> = ({ form, isPersonFilter, formType, options }) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = React.useState(false);
  const { isOpen, zIndex, close, meta } = useOfferOverlay(formType);

  const { trackOpenLead } = useGaEvents();

  useEffect(() => {
    if (isOpen) {
      trackOpenLead({ section: meta?.analyticsId || '' });
    }
  }, [isOpen]);

  const onSubmit = () => {
    setSubmitted(true);
  };

  return (
    <Overlay
      isOpen={isOpen}
      onClose={close}
      layout="default"
      zIndex={zIndex}
      hideClose={true}
    >
      <LayoutOverlay size="default">
        <Block
          width="narrow"
          theme="lighter"
        >
          {submitted ? (
            <React.Fragment>
              <OverlayTitle onClose={close} />
              <NonIdealState
                title={t('components.askOffer.send')}
                imageUrl="/img/success.png"
                buttons={[
                  {
                    title: t('components.askOffer.close'),
                    theme: 'primary',
                    onClick: () => {
                      setSubmitted(false);
                    },
                  },
                ]}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <OverlayTitle
                title={t('components.askOffer.title')}
                onClose={close}
              />
              <ContactForm
                onCancel={close}
                onSubmit={onSubmit}
                selectedForm={form}
                isPersonFilter={isPersonFilter}
                formType={formType}
                options={options}
                analyticsId={meta?.analyticsId}
              />
            </React.Fragment>
          )}
        </Block>
      </LayoutOverlay>
    </Overlay>
  );
};

OverlayAskOffer.displayName = 'OverlayAskOffer';

export default OverlayAskOffer;

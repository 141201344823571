'use client';

import * as React from 'react';
import { useState } from 'react';
import ConsentManager, { VendorConsentMap } from '@/components/cookies/ConsentManager';
import GoogleTagManager from '@/components/analytics/GoogleTagManager';
import Hotjar from '@/components/perfomance/Hotjar';
import { DidomiVendor } from '@/lib/enums/didomiVendor';

const SCRIPTS_WITH_CONSENT = [
  {
    vendorId: DidomiVendor.hotjar,
    script: Hotjar,
  },
];

const ExternalScripts: React.FC = () => {
  const [managerReady, setManagerReady] = useState(false);
  const [consentMap, setConsentMap] = useState<VendorConsentMap>({});

  return (
    <React.Fragment>
      <ConsentManager
        onReady={() => setManagerReady(true)}
        onConsent={setConsentMap}
      />
      {managerReady && (
        <React.Fragment>
          <GoogleTagManager />
          {SCRIPTS_WITH_CONSENT.filter((s) => !!consentMap[s.vendorId]).map((s) => (
            <s.script key={s.vendorId} />
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ExternalScripts;

import { useOverlay } from '@/components/hooks/useOverlay';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';
import { OfferFormType } from '@/components/overlays/AskOffer';

export type OfferOverlayMeta = {
  analyticsId?: string;
};

type IUseOfferOverlay = {
  open: (props?: OfferOverlayMeta) => void;
  isOpen: boolean;
  zIndex: number;
  close: () => void;
  meta: OfferOverlayMeta;
};

const useOfferOverlay = (type?: OfferFormType): IUseOfferOverlay => {
  const overlay = useOverlay<OfferOverlayMeta>(type === 'MARKETING' ? OverlayKey.request_marketing_offer : OverlayKey.request_offer);
  return {
    open: (props) => overlay.openOverlay(undefined, { meta: props }),
    isOpen: overlay.isOverlayOpen,
    close: overlay.closeOverlay,
    zIndex: overlay.zIndexOfOverlay,
    meta: overlay.overlayMeta,
  };
};

export default useOfferOverlay;

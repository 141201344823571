import * as React from 'react';
import { useEffect } from 'react';
import { initializeTagManger } from '@/lib/helpers/tagManager';

const GoogleTagManager: React.FC = () => {
  const id = process.env.NEXT_PUBLIC_TAG_MANAGER_ID!;

  useEffect(() => {
    initializeTagManger(id);
  }, []);

  return null;
};

export default GoogleTagManager;
